import { useContext } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { useCallOutMutation } from '../../gql/generated/graphql';

import PhoneNumberButton from './PhoneNumberButton';

import { useLienholder } from '../../hooks/useLienholder';
import { DealContext } from '../../libs/DealContext';
import { formatPhone, formatPhoneNumberForDisplay } from '../../libs/utils';

type CallWidgetProps = {
  toggleCallWidget: () => void;
  widgetOpen: boolean;
};

const CallWidget = ({ widgetOpen, toggleCallWidget }: CallWidgetProps) => {
  const { deal } = useContext(DealContext);
  const { lienholder } = useLienholder({ data: deal, skip: !deal.id });

  const [callOutbound] = useCallOutMutation();

  const lienholderPhoneNumber = formatPhoneNumberForDisplay(
    lienholder?.phone_numbers[0]?.phone_numbers[0], // Just use the first phone number
  );

  const onCall = (phoneNum?: string) => {
    if (!phoneNum) {
      toast.error('No phone number found');
      return;
    }

    callOutbound({
      variables: {
        calledNumber: formatPhone(phoneNum),
        id: deal.id,
      },
    });

    toggleCallWidget();
  };

  const dealHasAPhoneNumber =
    deal.customer.phone_number ||
    deal.customer.home_phone_number ||
    deal.cobuyer?.phone_number ||
    deal.cobuyer?.home_phone_number ||
    deal.contact?.phone_number ||
    deal.second_contact?.phone_number ||
    lienholderPhoneNumber;

  return (
    <Box pos="relative">
      {widgetOpen && (
        <>
          <Box position="absolute">
            <Box
              borderLeft="20px solid transparent"
              borderRight="20px solid transparent"
              borderBottomColor="oxfordBlue"
              borderBottomWidth="20px"
            />
            <Box
              borderLeft="20px solid transparent"
              borderRight="20px solid transparent"
              borderBottom="20px solid white"
              position="absolute"
              top="1px"
              zIndex={1000}
            />
            <Box bgColor="white" pos="absolute" width="20px" />
          </Box>
          <Box
            bgColor="white"
            borderColor="oxfordBlue"
            borderRadius="4px"
            borderWidth="1px"
            display="flex"
            flexDir="column"
            p={3}
            gap={1}
            position="absolute"
            top="10px"
            transform="translateX(-45%)"
            zIndex={100}
          >
            {dealHasAPhoneNumber ? (
              <>
                <PhoneNumberButton
                  sectionLabel="Buyer:"
                  phoneNumber={deal.customer.phone_number}
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  phoneNumber={deal.customer.home_phone_number}
                  buttonLabel="HOME PHONE"
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  sectionLabel="Cobuyer:"
                  phoneNumber={deal.cobuyer?.phone_number}
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  phoneNumber={deal.cobuyer?.home_phone_number}
                  buttonLabel="HOME PHONE"
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  sectionLabel="Contact:"
                  phoneNumber={deal.contact?.phone_number}
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  sectionLabel="Second Contact:"
                  phoneNumber={deal.second_contact?.phone_number}
                  buttonLabel="CELL PHONE"
                  handleClick={onCall}
                />
                <PhoneNumberButton
                  sectionLabel="Lienholder:"
                  phoneNumber={lienholderPhoneNumber}
                  buttonLabel={lienholder?.display_name}
                  handleClick={onCall}
                />
              </>
            ) : (
              <Text color="black" whiteSpace="nowrap">
                No phone numbers
              </Text>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CallWidget;
