import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import Loader from '../Loader';

type LEContainerProps = PropsWithChildren<
  BoxProps & {
    isLoading?: boolean;
  }
>;

const LeaseEndContainer = ({ isLoading, children, ...rest }: LEContainerProps) => {
  return (
    <Box>
      <Loader isLoading={isLoading ?? false} />
      <Box
        bg="snowyWhite"
        p={{ base: '5px', sm: '15px' }}
        pb={{ base: '85px', sm: '95px' }} // 80px added to account for the `CurrentCallPanel`.
        overflow="hidden"
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LeaseEndContainer;
