import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';

import { DuplicatedInfo, useOnDuplicatedInfoSubscription } from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DuplicatedInfoHandler = () => {
  const [deals, setDeals] = useState<DuplicatedInfo[]>([]);

  useOnDuplicatedInfoSubscription({
    onData: ({ data }) => {
      if (!data.data?.onDuplicatedInfo) {
        return;
      }

      const newDeal = data.data.onDuplicatedInfo;

      if (!deals.some((deal) => deal.deal_id === newDeal.deal_id)) {
        setDeals([...deals, newDeal]);
      }
    },
  });

  return (
    <>
      {deals.map((d) => (
        <NotificationToast
          key={`duplicated-deal-${d.deal_id}`}
          dealId={d.deal_id ?? null}
          slideFadeIn={!!d.first_name}
          title={`${d.first_name} ${d.last_name} - Deal ${d.deal_id}`}
          titleIcon={FaRegCopy}
          titleIconColor="green"
          subTitle="Matching Web App Detected: Review Data"
          details={
            <Text>
              An abandoned web app that matches this deal was detected. Please review the pinned
              note in the deal for any missing data.
            </Text>
          }
          onClose={() => setDeals(deals.filter((p) => p.deal_id !== d.deal_id))}
        />
      ))}
    </>
  );
};

export default DuplicatedInfoHandler;
