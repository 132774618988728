import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import {
  WaitingCall,
  useCallInProgressQuery,
  useGetUserTwilioNumberQuery,
  useGetWaitingCallsQuery,
  useInProgressCallsSubscription,
  useLoggedUserQuery,
  useOnWaitingQueueSubscription,
} from '../gql/generated/graphql';

import TransferCallModal from '../components/Calls/transferCallModal';

export type Call =
  | {
      fullname: string;
      phoneNumber: string | undefined;
      toNumber: string | undefined;
      conferenceId: string;
      dealId: number;
      dealType?: string;
      isThunder: boolean;
      callId: string;
      dealState: string;
      started_at: string;
      huntGroupSlug?: string;
      is_outbound?: boolean;
    }
  | undefined;

type CallsContextType = {
  refresh: () => void;
  isRefreshing?: boolean;
  current: Call | undefined;
  setCurrent: (value: Call | undefined) => void;
  waitingCalls: Call[];
  activeCall: Call | null;
  twilioNumber: string | undefined;
  handleTransferCallModal: (isVisible: boolean) => void;
  isVisibleTransferCallModal: boolean;
  callAlreadyAnswered: boolean;
  setCallAlreadyAnswered: (v: boolean) => void;
  answeredAt: Date | null;
};

export const CallsContext = createContext<CallsContextType>({
  refresh: () => false,
  handleTransferCallModal: () => false,
  current: undefined,
  setCurrent: () => undefined,
  isVisibleTransferCallModal: false,
  isRefreshing: false,
  waitingCalls: [],
  activeCall: undefined,
  twilioNumber: undefined,
  callAlreadyAnswered: false,
  setCallAlreadyAnswered: () => undefined,
  answeredAt: null,
});

type WaitingCallObjectType = {
  [key: string]: WaitingCall[];
};

export const CallsProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth0();

  // `current` is what is being presented to dd user
  // it could be a waiting call or an active call
  const [current, setCurrent] = useState<Call>(undefined);
  const [waitingCallsObject, setWaitingCallsObject] = useState<WaitingCallObjectType>({});
  const [waitingCalls, setWaitingCalls] = useState<Call[]>([]);
  const [activeCall, setActiveCall] = useState<Call | null>(null);
  const [answeredAt, setAnsweredAt] = useState<Date | null>(null);
  const [callAlreadyAnswered, setCallAlreadyAnswered] = useState(false);
  const [isVisibleTransferCallModal, setIsVisibleTransferCallModal] = useState(false);

  // waiting queue
  const { data: getWaitingQueueQueryData } = useGetWaitingCallsQuery({
    skip: !user?.sub,
  });
  const { data: waitingQueueSubscriptionData, loading } = useOnWaitingQueueSubscription();
  const { data: loggedUserData } = useLoggedUserQuery({
    skip: !user?.sub,
  });

  // in progress calls
  const { data: inProgressCallsQueryData } = useCallInProgressQuery({
    skip: !user?.sub,
  });
  const { data: inProgressCallsSubscriptionData } = useInProgressCallsSubscription();

  const userTwilioNumber = useGetUserTwilioNumberQuery({
    skip: !user?.sub,
  });

  // in progress calls logic
  useEffect(() => {
    const loggedUser = loggedUserData?.loggedUser;
    if (loggedUser && loggedUser.call_status === 'inactive') {
      return;
    }

    const inProgressCallsFromSubscription = inProgressCallsSubscriptionData?.inProgressCalls;
    const inProgressCallFromQuery = inProgressCallsQueryData?.callInProgress;

    const inProgressCalls = inProgressCallsFromSubscription?.length
      ? inProgressCallsFromSubscription
      : [inProgressCallFromQuery];

    const currentInProgressCall = inProgressCalls?.find(
      (item) => item?.agent_user_id === user?.sub,
    );

    if (currentInProgressCall && !activeCall) {
      const fromSplit = currentInProgressCall?.from?.split('-') ?? [];
      setActiveCall({
        fullname: currentInProgressCall.customer_name ?? '',
        dealId: currentInProgressCall.deal_id as number,
        dealType: currentInProgressCall.deal_type ?? '',
        isThunder: currentInProgressCall.is_thunder ?? false,
        phoneNumber: fromSplit[0],
        toNumber: currentInProgressCall.to ?? '',
        conferenceId: currentInProgressCall?.conference_id ?? '',
        callId: fromSplit[1],
        dealState: currentInProgressCall?.deal_state ?? '',
        started_at: waitingCalls?.[0]?.started_at ?? '',
        huntGroupSlug: currentInProgressCall?.hunt_group_slug ?? '',
        is_outbound: !!currentInProgressCall?.is_outbound,
      });
    } else if (!currentInProgressCall && activeCall) {
      setActiveCall(null);
    }
  }, [inProgressCallsSubscriptionData?.inProgressCalls, inProgressCallsQueryData?.callInProgress]);

  // logic for waiting calls from query
  useEffect(() => {
    const { data: waitingQueuesObjectFromQuery = null } =
      getWaitingQueueQueryData?.getWaitingCalls ?? {};

    if (waitingQueuesObjectFromQuery) {
      setWaitingCallsObject(waitingQueuesObjectFromQuery as WaitingCallObjectType);
    }
  }, [getWaitingQueueQueryData?.getWaitingCalls]);

  // logic for waiting calls from subscription
  useEffect(() => {
    const { data: waitingQueueFromSubscription = null, hunt_group_slug = '' } =
      waitingQueueSubscriptionData?.onWaitingQueue ?? {};

    // if a waiting queue has come through a subscription
    // replace the value in the waitingCallsObject
    if (waitingQueueFromSubscription && hunt_group_slug) {
      setWaitingCallsObject({
        ...waitingCallsObject,
        [hunt_group_slug]: waitingQueueFromSubscription as WaitingCall[],
      });
    }
  }, [waitingQueueSubscriptionData?.onWaitingQueue]);

  // waiting calls object
  useEffect(() => {
    // merge waiting calls from object based on time
    const waitingQueue = Object.values(waitingCallsObject)
      .flat()
      .sort(
        (a, b) =>
          new Date(a.started_at as string).getTime() - new Date(b.started_at as string).getTime(),
      );

    const waitingQueueCleaned = (waitingQueue as WaitingCall[])?.map((item: WaitingCall): Call => {
      // FROM +18018983841-CA4914195f6e4b74245c24c0b06595914d-+18585001062-CFc7191b3ce10fb2952e149f6aa6f06072
      const fromSplit = item?.from?.split('-') ?? [];
      return {
        fullname: item.customer_name ?? '',
        dealId: item.deal_id as number,
        dealType: item.deal_type ?? '',
        isThunder: item.is_thunder ?? false,
        phoneNumber: fromSplit[0],
        toNumber: fromSplit[2],
        conferenceId: item?.conference_id ?? '',
        callId: fromSplit[1],
        dealState: item?.deal_state ?? '',
        started_at: item?.started_at ?? '',
        huntGroupSlug: item?.hunt_group_slug ?? '',
      };
    });

    setWaitingCalls(waitingQueueCleaned);
  }, [waitingCallsObject]);

  // logic to set `current`
  useEffect(() => {
    if (loggedUserData?.loggedUser?.call_status === 'inactive') {
      // if user is not available, set current to undefined
      setCurrent(undefined);
      // if user is direct we still want to show them the call but not the waiting queue calls
    } else if (loggedUserData?.loggedUser?.call_status === 'direct') {
      if (activeCall) {
        setCurrent(activeCall);
      } else {
        setCurrent(undefined);
      }
    } else if (activeCall) {
      // if there is an active call, set current to active call
      setCurrent(activeCall);
    } else if (!activeCall && waitingCalls?.length) {
      // if there is no active call, but there are waiting calls, set current to first waiting call
      setCurrent(waitingCalls[0]);
    } else if (!activeCall && !waitingCalls?.length) {
      // if there is no active call and no waiting calls, set current to undefined
      setCurrent(undefined);
    }
  }, [waitingCalls, activeCall, loggedUserData?.loggedUser]);

  useEffect(() => {
    if (activeCall && !answeredAt) {
      setAnsweredAt(new Date());
    } else if (!activeCall && answeredAt) {
      setAnsweredAt(null);
    }
  }, [activeCall]);

  const handleTransferCallModal = (isVisible: boolean) => {
    setIsVisibleTransferCallModal(isVisible);
  };

  return (
    <CallsContext.Provider
      value={{
        refresh: () => '',
        activeCall,
        current,
        setCurrent,
        isRefreshing: loading,
        waitingCalls,
        handleTransferCallModal,
        isVisibleTransferCallModal,
        twilioNumber: userTwilioNumber.data?.getUserTwilioNumber ?? undefined,
        callAlreadyAnswered,
        setCallAlreadyAnswered,
        answeredAt,
      }}
    >
      {children}
      {isVisibleTransferCallModal ? <TransferCallModal /> : null}
    </CallsContext.Provider>
  );
};

export const useCallsContext = () => useContext(CallsContext);
