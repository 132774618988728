import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';

import {
  AffiliateDuplicateFound,
  useOnAffiliateDuplicateFoundSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DuplicatedAffiliateHandler = () => {
  const [deals, setDeals] = useState<AffiliateDuplicateFound[]>([]);

  useOnAffiliateDuplicateFoundSubscription({
    onData: ({ data }) => {
      if (!data.data?.onAffiliateDuplicateFound) {
        return;
      }

      const newDeal = data.data.onAffiliateDuplicateFound;

      if (!deals.some((deal) => deal.deal_id === newDeal.deal_id)) {
        setDeals([...deals, newDeal]);
      }
    },
  });

  return (
    <>
      {deals.map((d) => (
        <NotificationToast
          key={`duplicated-affiliate-import-${d.deal_id}`}
          dealId={d.deal_id ?? null}
          slideFadeIn={!!d.first_name}
          title={`${d.first_name} ${d.last_name} - Deal ${d.deal_id}`}
          titleIcon={FaRegCopy}
          titleIconColor="green"
          subTitle="Duplicate Lead From Lending Tree"
          details={
            <Text>
              A duplicate credit app was submitted through Lending Tree with a phone number or email
              matching this deal. Click into your deal to see more details.
            </Text>
          }
          onClose={() => setDeals(deals.filter((p) => p.deal_id !== d.deal_id))}
        />
      ))}
    </>
  );
};

export default DuplicatedAffiliateHandler;
