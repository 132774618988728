import { Dispatch, MouseEventHandler, SetStateAction } from 'react';

import { Button, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';

import RequestPayoffButton from '../EstimateForm/RequestPayoffButton';
import Modal from '../shared/Modal';

import CopyButton from './CopyButton';
import ReviveBootButton from './ReviveBootButton';

import { PermissionEnum } from '../../constants/permissions';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useUser } from '../../hooks/useUser';

interface DuplicateDealsModalProps {
  isDuplicateModalOpen?: boolean;
  closeDuplicateModal?: () => void;
  setShowPayoffRequestButton: Dispatch<SetStateAction<boolean>>;
  showPayoffRequestButton: boolean;
  duplicateDeals: Deal[];
  submitEstimate?: (values: Deal, setSubmitting: (isSubmitting: boolean) => void) => void;
  upsertEstimateLoading?: boolean;
}

export const DuplicateDealsModal = ({
  isDuplicateModalOpen,
  closeDuplicateModal,
  setShowPayoffRequestButton,
  showPayoffRequestButton,
  duplicateDeals,
  submitEstimate,
  upsertEstimateLoading,
}: DuplicateDealsModalProps) => {
  const user = useUser();

  // if the user has permission to create duplicate deals or if all the duplicate deals were created by the user
  const canCreateDuplicateDeals =
    useHasPermission(PermissionEnum.CreateDuplicateDeals) ||
    duplicateDeals.every((d) => d.setter?.id === user.id);

  // order the duplicate deals
  const sortedDuplicateDeals = duplicateDeals.sort((a, b) => {
    return (
      new Date(b.creation_date_utc || '').getTime() - new Date(a.creation_date_utc || '').getTime()
    );
  });

  const showReviveBootButton = sortedDuplicateDeals.every((d) => d.state === DealStateEnum.Booted);

  return (
    <Modal
      title="Duplicate Deal"
      isOpen={!!isDuplicateModalOpen}
      onClose={() => {
        if (closeDuplicateModal) {
          closeDuplicateModal();
        }
        setShowPayoffRequestButton(false);
      }}
      leftButtons={
        <Button
          onClick={() => {
            if (closeDuplicateModal) {
              closeDuplicateModal();
            }
            setShowPayoffRequestButton(false);
          }}
          variant="warning"
        >
          CANCEL
        </Button>
      }
      rightButtons={
        showReviveBootButton ? (
          <ReviveBootButton
            duplicateDeals={sortedDuplicateDeals}
            closeDuplicateModal={closeDuplicateModal}
          />
        ) : !canCreateDuplicateDeals ? (
          <CopyButton
            duplicateDeals={sortedDuplicateDeals}
            closeDuplicateModal={closeDuplicateModal}
          />
        ) : showPayoffRequestButton ? (
          <RequestPayoffButton />
        ) : (
          <Button
            isLoading={upsertEstimateLoading}
            onClick={submitEstimate as unknown as MouseEventHandler<HTMLElement>}
          >
            CREATE DEAL
          </Button>
        )
      }
      size="4xl"
    >
      <Text mb={8}>
        The information you have entered matches one or more deals already in the system. Please see
        below and send to your manager for review.
      </Text>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Vehicle</Th>
            <Th>Number</Th>
            <Th>State</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedDuplicateDeals.map((d) => (
            <Tr key={d.id}>
              <Td>
                <Link
                  href={`/deals/${d.id}`}
                  target="_blank"
                  rel="noreferrer"
                  width="10%"
                  fontWeight="bold"
                  color="mediumElectricBlue"
                >
                  {getFullName(d.customer)}
                </Link>
              </Td>
              <Td>
                {d.car.year} {d.car.make} {d.car.model}
              </Td>
              <Td>{d.customer.phone_number}</Td>
              <Td>{DealStateLabelMap[d.state]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Modal>
  );
};
