import { ReactNode } from 'react';

import { PermissionEnum } from '../constants/permissions';
import { useHasPermission } from '../hooks/useHasPermission';

export interface CanProps {
  I: PermissionEnum | PermissionEnum[];
  children: ReactNode | ((hasPermission: boolean) => ReactNode);
}

export const Can = ({ I: permission, children }: CanProps) => {
  const hasPermission = useHasPermission(permission);

  return (
    <>{typeof children === 'function' ? children(hasPermission) : hasPermission && children}</>
  );
};
