import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { MdTimer } from 'react-icons/md';

import { DealImported, useOnDealImportedSubscription } from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const InactivityImportHandler = () => {
  const [deals, setDeals] = useState<DealImported[]>([]);

  useOnDealImportedSubscription({
    onData: ({ data }) => {
      if (!data.data?.onDealImported) {
        return;
      }

      const newDeal = data.data.onDealImported;

      if (!deals.some((deal) => deal.deal_id === newDeal.deal_id)) {
        setDeals([...deals, newDeal]);
      }
    },
  });

  return (
    <>
      {deals.map((d) => (
        <NotificationToast
          key={`${d.deal_id}`}
          dealId={d.deal_id ?? null}
          slideFadeIn={!!d.first_name}
          title={`${d.first_name} ${d.last_name} - Deal ${d.deal_id}`}
          titleIcon={MdTimer}
          titleIconColor="green"
          subTitle="Inactivity Import"
          details={
            <Text>
              This deal started from a sales link provided by an advisor and imported after
              inactivity in the Web flow.
            </Text>
          }
          onClose={() => setDeals(deals.filter((p) => p.deal_id !== d.deal_id))}
        />
      ))}
    </>
  );
};

export default InactivityImportHandler;
