import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  Button,
  ButtonProps,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { mdiPhoneHangupOutline, mdiPhoneOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { BiCollapse } from 'react-icons/bi';
import { MdOutlinePhoneForwarded } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useAddParticipantMutation, useHangUpCallMutation } from '../../gql/generated/graphql';

import SmallCurrentCallPanel from './SmallCurrentCallPanel';

import useDebounce from '../../hooks/useDebounce';
import { useCallsContext } from '../../libs/callsContext';
import { formatPhoneNumberForDisplay } from '../../libs/utils';

const CurrentCallPanel = () => {
  const {
    handleTransferCallModal,
    setCallAlreadyAnswered,
    activeCall,
    current,
    setCurrent,
    answeredAt,
  } = useCallsContext();
  const [panelMinimized, setPanelMinimized] = useState<boolean>(false);
  const [ringtone, setRingtone] = useState<HTMLAudioElement>();
  const [addParticipantMutation, { data: addParticipantMutationData }] =
    useAddParticipantMutation();
  const panelRef = useRef<HTMLDivElement>(null);
  const [waitingMilliseconds, setWaitingMilliseconds] = useState(0);
  const [durationMilliseconds, setDurationMilliseconds] = useState(0);
  const [hangUpCallMutation] = useHangUpCallMutation();

  const leftButtonProps: ButtonProps = {
    variant: 'boot',
    size: 'lgWithIconLeft',
    leftIcon: <Icon path={mdiPhoneHangupOutline} size="20px" />,
  };

  const rightButtonProps: ButtonProps = {
    size: 'lgWithIconLeft',
  };

  const stopRingtone = () => {
    if (ringtone) {
      ringtone.pause();
      ringtone.currentTime = 0;
    }
  };

  // It shows hours only if needed. `utcToZonedTime` guarantees that the hours start from 0.
  const getTimeInterval = (milliseconds: number) =>
    format(utcToZonedTime(milliseconds, 'UTC'), milliseconds >= 3600000 ? 'HH:mm:ss' : 'mm:ss');

  const createInterval = (
    date: Date | string | null | undefined,
    setMilliseconds: Dispatch<SetStateAction<number>>,
  ) => {
    const intervalId = setInterval(() => {
      const timer = Math.floor(new Date().getTime() - new Date(date ?? '').getTime());
      setMilliseconds(Math.floor(timer) || 0);
      if (!date) {
        clearInterval(intervalId);
      }
    }, 1_000);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    return createInterval(current?.started_at, setWaitingMilliseconds);
  }, [current?.started_at]);

  useEffect(() => {
    return createInterval(answeredAt, setDurationMilliseconds);
  }, [answeredAt]);

  useEffect(() => {
    const audio = new Audio('https://leaseend-service-9352.twil.io/incoming_call.mp3');
    audio.volume = 0.2;
    setRingtone(audio);
  }, []);

  useEffect(() => {
    if (addParticipantMutationData?.addParticipant?.call_already_answered) {
      setCallAlreadyAnswered(true);
    }
  }, [addParticipantMutationData]);

  useEffect(() => {
    if (current && !activeCall) {
      ringtone?.play();
    } else {
      stopRingtone();
    }
  }, [activeCall, ringtone, current]);

  const debouncedAnswerCall = useDebounce(
    async (huntGroup: string) => {
      try {
        await addParticipantMutation({
          variables: {
            hunt_group_slug: huntGroup,
          },
        });
      } catch (error) {
        toast.error('Failed to answer call');
      }
    },
    500,
    {
      leading: true,
      trailing: false,
      maxWait: 900,
    },
  );

  const onClose = () => {
    setCurrent(undefined);
    handleTransferCallModal(false);
    stopRingtone();
  };

  // Used to spread data across all open tabs
  const broadcastIgnoreCall = () => {
    localStorage.setItem('callIgnored', JSON.stringify('callIgnored'));
    localStorage.removeItem('callIgnored');
  };

  const receiveIgnoreCall = (ev: StorageEvent) => {
    if (ev.key === 'callIgnored') {
      onClose();
    }
  };

  // used for ringtone stoppage across multiple tabs, hides panel
  useEffect(() => {
    if (current) {
      window.addEventListener('storage', receiveIgnoreCall);
    } else {
      window.removeEventListener('storage', receiveIgnoreCall);
      handleTransferCallModal(false);
    }
  }, [current]);

  const onIgnoreClick = () => {
    broadcastIgnoreCall();
    onClose();
  };

  const onHangUpClick = async () => {
    await hangUpCallMutation({
      variables: {
        conference_id: activeCall?.conferenceId ?? '',
      },
    });

    onClose();
  };

  const handlePanelClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (panelRef.current === e.target) {
      setPanelMinimized(true);
    }
  };

  if (!current) {
    return null;
  }

  if (panelMinimized) {
    return (
      <SmallCurrentCallPanel
        onPanelClick={setPanelMinimized}
        phoneNumber={current.phoneNumber}
        name={current.fullname}
      />
    );
  }

  const isRefi =
    current.dealType === 'refi' ||
    [
      '8339991584',
      '+18339991584',
      '8339991782',
      '+18339991782',
      '2086708843',
      '+12086708843',
      '+18335694156',
      '8335694156',
      '+18556070762',
      '8556070762',
      // fake mailer
      '+15205829264',
      '5205829264',
    ].includes(current.toNumber as string);

  // const isThunder = current.isThunder || false;
  const isThunder = false;

  const bgColor = isThunder ? 'thunderPurple' : isRefi ? 'refiCyan' : 'midnightblue';

  return (
    <Flex
      w="80%"
      maxW="1500px"
      h="80px" // If the height is changed, update `LEContainer`.
      position="fixed"
      bottom={0}
      right={0}
      left={0}
      mx="auto"
      bg={bgColor}
      alignItems="center"
      borderTopRadius="10px"
      zIndex={2000}
      justifyContent="space-between"
      fontWeight="bold"
      ref={panelRef}
      onClick={(event) => {
        handlePanelClick(event);
      }}
      color="white"
    >
      <HStack ml={4} h="full" gap={12}>
        {activeCall?.phoneNumber ? (
          <Button onClick={onHangUpClick} {...leftButtonProps}>
            END
          </Button>
        ) : (
          <Button onClick={onIgnoreClick} {...leftButtonProps}>
            IGNORE
          </Button>
        )}

        {activeCall?.phoneNumber ? (
          <HStack h="full" gap={2}>
            {activeCall?.fullname || activeCall?.dealId ? (
              <>
                <VStack spacing={0}>
                  {activeCall?.fullname ? <Text>{activeCall?.fullname}</Text> : null}
                  {activeCall?.dealId ? (
                    <Link
                      href={activeCall?.dealId ? `/deals/${activeCall?.dealId}` : undefined}
                      isExternal
                      fontSize="md"
                      style={{ textDecoration: 'underline' }}
                      mt={0}
                    >
                      {activeCall?.dealId}
                    </Link>
                  ) : null}
                </VStack>
                <Divider orientation="vertical" h="50%" />
              </>
            ) : null}
            <VStack spacing={0}>
              <Text>{formatPhoneNumberForDisplay(activeCall?.phoneNumber)}</Text>
              <Text textTransform="capitalize">{getTimeInterval(durationMilliseconds)}</Text>
            </VStack>
          </HStack>
        ) : (
          <HStack h="full" gap={2}>
            <Text>{formatPhoneNumberForDisplay(current?.phoneNumber)}</Text>
            <Divider orientation="vertical" h="50%" />
            <Text textTransform="capitalize">Waiting {getTimeInterval(waitingMilliseconds)}</Text>
          </HStack>
        )}
      </HStack>

      <HStack mr={3}>
        {activeCall?.phoneNumber ? (
          <Button
            variant={isRefi ? 'textBorder' : isThunder ? 'secondary' : 'secondaryDarkBg'}
            leftIcon={<MdOutlinePhoneForwarded size={20} />}
            onClick={() => handleTransferCallModal(true)}
            {...rightButtonProps}
          >
            TRANSFER
          </Button>
        ) : (
          <Button
            leftIcon={<Icon path={mdiPhoneOutline} size="20px" />}
            onClick={() => debouncedAnswerCall(current.huntGroupSlug || '')}
            {...rightButtonProps}
          >
            ANSWER
          </Button>
        )}

        <IconButton
          variant="icon"
          icon={<BiCollapse />}
          color="white"
          fontSize="3xl"
          aria-label="minimize panel"
          onClick={() => setPanelMinimized(true)}
        />
      </HStack>
    </Flex>
  );
};

export default CurrentCallPanel;
