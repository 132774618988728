import { gql } from '@apollo/client/core';

import { PayoffRequestStatus, TemporaryInfo } from './generated/graphql';

import { User } from '../types/user';

import { Deal } from './dealGql';

export enum FailPayoffRequestReasonEnum {
  COULD_NOT_REACH_CUSTOMER_FOR_CONFERENCE = "Couldn't reach customer for conference",
  CUSTOMER_NEEDS_TO_CALL = 'Customer needs to call',
  TOO_LITTLE_INFORMATION = 'Too little information',
  INCORRECT_SSN = 'Incorrect SSN',
  INCORRECT_DOB = 'Incorrect DOB',
  INCORRECT_VIN = 'Incorrect VIN',
  LIENHOLDER_REFUSED_TO_PROVIDE = 'Lienholder refused to provide',
  OTHER = 'Other',
}

export class FailReason {
  reason: FailPayoffRequestReasonEnum;
  comment: string;

  constructor(reason: FailPayoffRequestReasonEnum, comment = '') {
    this.reason = reason;
    this.comment = comment;
  }
}

export const activePayoffRequestStatuses = [
  PayoffRequestStatus.Pending,
  PayoffRequestStatus.Fetching,
  PayoffRequestStatus.FailureRequested,
];

export class PayoffRequest {
  id?: number;
  deal_id?: number;
  deal: Deal;
  temporary_info_id?: number;
  temporary_info?: TemporaryInfo;
  status?: PayoffRequestStatus;
  vehicle_payoff?: number;
  sales_tax?: number;
  completed_date?: Date;
  good_through_date?: Date;
  created_at?: Date;
  updated_at?: Date;
  fail_reason?: FailReason;
  payoff_clerk_id?: string;
  payoff_clerk?: User;
  needs_payoff_documents?: boolean;
  payoff_documents_uploaded?: boolean;
  requestor?: User;

  constructor() {
    this.deal = new Deal();
  }
}

export const PayoffRequestPopulatedFragment = gql`
  fragment PayoffRequestPopulatedFragment on payoffRequestPopulated {
    id
    created_at
    deal_id
    deal {
      id
      closer {
        id
        name
      }
      car {
        vehicle_type
        fuel_type
        year
        make
        model
        vin
        mileage
        payoff {
          account_number
          lienholder_name
          lienholder_slug
          lender_name
        }
      }
      customer {
        id
        first_name
        last_name
        ssn
        dob
        phone_number
        home_phone_number
        address {
          city
          county
          zip
          state
        }
      }
      financial_info {
        buyer_not_lessee
      }
      closer_id
      created_at
      notifications {
        id
        message
        created_at
        seen
      }
    }
    temporary_info_id
    temporary_info {
      id
      data {
        state
        city
        county
        year
        make
        model
        vin
        mileage
        lh_account_number
        lienholder
        first_name
        last_name
        dob
        phone_number
        zip
        ssn
        vehicle_type
        fuel_type
      }
    }
    status
    vehicle_payoff
    sales_tax
    completed_date
    good_through_date
    created_at
    fail_reason
    payoff_clerk_id
    payoff_clerk {
      id
      name
    }
    needs_payoff_documents
    payoff_documents_uploaded
    requestor {
      id
      name
    }
  }
`;

export const PayoffDashboardDeals = gql`
  query payoffDashboardDeals {
    payoffDashboardDeals {
      payoffRequests {
        ...PayoffRequestPopulatedFragment
      }
    }
  }
`;

export const payoffRequestConfirm = gql`
  mutation payoffRequestConfirm($payoffRequest: payoffRequestConfirmInput!) {
    payoffRequestConfirm(payoffRequest: $payoffRequest) {
      id
      vehicle_payoff
      good_through_date
    }
  }
`;

export const updatePayoffClerk = gql`
  mutation updatePayoffClerk($payoff_request_id: ID, $payoff_clerk_id: String) {
    updatePayoffClerk(payoff_request_id: $payoff_request_id, payoff_clerk_id: $payoff_clerk_id)
  }
`;

export const payoffRequestFailureRequest = gql`
  mutation payoffRequestFailureRequest($payoffRequest: payoffRequestInput!) {
    payoffRequestFailureRequest(payoffRequest: $payoffRequest) {
      id
      fail_reason
    }
  }
`;

export const payoffRequestFail = gql`
  mutation payoffRequestFail($payoffRequest: payoffRequestInput!) {
    payoffRequestFail(payoffRequest: $payoffRequest) {
      id
      fail_reason
    }
  }
`;

export const createPayoffRequest = gql`
  mutation createPayoffRequest($deal_id: ID!) {
    createPayoffRequest(deal_id: $deal_id) {
      deal_id
      status
      vehicle_payoff
      sales_tax
      completed_date
      good_through_date
    }
  }
`;

export const payoffRequestCancel = gql`
  mutation payoffRequestCancel($payoffRequestId: ID!) {
    payoffRequestCancel(payoffRequestId: $payoffRequestId) {
      id
    }
  }
`;

export const onPayoffRequestUpdate = gql`
  subscription OnPayoffRequestUpdate {
    onPayoffRequestUpdate {
      ...PayoffRequestPopulatedFragment
    }
  }
`;

export const onPayoffRequestStatusUpdate = gql`
  subscription onPayoffRequestStatusUpdate($dealId: ID) {
    onPayoffRequestStatusUpdate(dealId: $dealId) {
      id
      deal_id
      temporary_info_id
      status
      vehicle_payoff
      good_through_date
      sales_tax
      completed_date
      fail_reason
      created_at
      updated_at
      deal {
        id
        state
        customer {
          first_name
          last_name
        }
        car {
          payoff {
            user_entered_total_payoff
            vehicle_payoff
            sales_tax_from_payoff
            payoff_includes_sales_tax
            sales_tax_from_payoff_entered_manually
            good_through_date
          }
        }
        tags {
          id
          slug
          display_name
          color
          is_active
          is_dashboard_visible
        }
      }
    }
  }
`;

export const payoffRequestQuery = gql`
  query payoffRequest($deal_id: ID) {
    payoffRequest(deal_id: $deal_id) {
      id
      status
    }
  }
`;

export const payoffRequestUpdate = gql`
  mutation payoffRequestUpdate($payoffRequest: payoffRequestInput!) {
    payoffRequestUpdate(payoffRequest: $payoffRequest) {
      id
    }
  }
`;
