import Big from 'big.js';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { cloneDeep } from 'lodash';
import toPath from 'lodash/toPath';
import { ObjectSchema, ValidationError } from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { Deal } from '../gql/dealGql';
import { Maybe, Message } from '../gql/generated/graphql';

import { getMinutesAgo } from '../utils/dates';

export const getTimezoneStr = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (date: string | undefined, tz?: string) => {
  const dt = date ? new Date(date) : new Date();
  const zonedDate = utcToZonedTime(dt, tz || getTimezoneStr());
  return zonedDate.toLocaleDateString();
};

export const formatDateWithTime = (date: string | undefined, tz?: Maybe<string>) => {
  const dt = date ? new Date(date) : new Date();
  const zonedDate = utcToZonedTime(dt, tz || getTimezoneStr());
  return `${zonedDate.toLocaleDateString()} ${zonedDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export const formatDateWithTimeVerbose = (date: string | undefined, tz?: Maybe<string>) => {
  const dt = date ? new Date(date) : new Date();
  const zonedDate = utcToZonedTime(dt, tz || getTimezoneStr());

  return format(zonedDate, "EEEE, MMMM do 'at' h:mm a");
};

export const formatDateNoYearWithTime = (date: string | undefined, tz?: string) => {
  const dt = date ? new Date(date) : new Date();
  const zonedDate = utcToZonedTime(dt, tz || getTimezoneStr());
  return `${zonedDate.toLocaleDateString([], {
    month: '2-digit',
    day: '2-digit',
  })} - ${zonedDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

export const formatTime = (date: string | undefined, tz?: string) => {
  const dt = date ? new Date(date) : new Date();
  const zonedDate = utcToZonedTime(dt, tz || getTimezoneStr());
  return zonedDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDateIfTrue = (date?: Date | string | null, tz?: string) => {
  if (!date) {
    return '-';
  }

  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  const zonedDate = utcToZonedTime(parsedDate, tz || getTimezoneStr());
  return zonedDate.toLocaleDateString();
};

export const formatDateISO = (date?: Date | string | null) => {
  if (!date) {
    return '';
  }

  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return parsedDate.toISOString().split('T')[0];
};

export const getUTCDate = (date: string | Date) => {
  const zonedDate = typeof date === 'string' ? new Date(date) : date;

  return new Date(zonedDate.getUTCFullYear(), zonedDate.getUTCMonth(), zonedDate.getUTCDate());
};

export const formatDateTimeISOWithUTC = (date: string | Date) => {
  const zonedDate = typeof date === 'string' ? new Date(date) : date;

  return `${format(new Date(zonedDate ?? ''), 'yyyy-MM-dd')}T00:00:00.000Z`;
};

export const formatMoney = (value: number | undefined, options?: { noDecimal: boolean }) =>
  value?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: options?.noDecimal ? 0 : 2,
  }) ?? '';

export const formatMileage = (value: number | undefined) =>
  value?.toLocaleString('en-US', { minimumFractionDigits: 0 });

export const formatPhone = (phoneNumber?: string) =>
  phoneNumber ? `+1${phoneNumber.replace(/[^0-9]/g, '')}` : '';

export const formatPhoneWithParentheses = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  const droppedNat = phoneNumber.replace('+1', '');
  const phone = droppedNat.slice(0, 3);
  const num = droppedNat.slice(3, 6);
  const ber = droppedNat.slice(6, 10);

  return `(${phone}) ${num}-${ber}`;
};

// same function as utils/formatPhoneNumber.ts
export const formatPhoneNumberForDisplay = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  // remove non digits
  const d = phoneNumber?.replace('+1', '').replace(/\D/g, '') ?? '';

  // format
  const l = d.length;
  if (l > 6) {
    return `(${d.slice(0, 3)}) ${d.slice(3, 6)}-${d.slice(6)}`;
  }

  if (l > 3) {
    return `(${d.slice(0, 3)}) ${d.slice(3, 6)}`;
  }

  if (l > 0) {
    return `(${d.slice(0, 3)}`;
  }

  return phoneNumber;
};

export const sumArr = (arr: (number | undefined)[]): number =>
  arr.reduce((acc, curr) => acc.plus(curr ?? 0), new Big(0)).toNumber();

// YUP Helpers
export const passValuesToSchema = <T extends FormikValues>(
  values: T,
  schema: unknown,
  deal?: Deal,
) => {
  try {
    validateYupSchema(values, schema, true, deal || values);
  } catch (err) {
    return yupToFormErrors<T>(err);
  }
  return yupToFormErrors<T>({});
};

const deleteProp = (obj: object, path: string[]) => {
  const curr = path.shift() as keyof typeof obj;

  if (path.length === 0) {
    // eslint-disable-next-line no-param-reassign
    delete obj[curr];
  } else {
    deleteProp(obj[curr], path);
  }
};

export const removeFailedValidation = <T extends FormikValues>(
  values: T,
  schema: ObjectSchema<ObjectShape>,
) => {
  const clonedValues = cloneDeep(values);

  // Deletes any fields that fail autosave validation
  try {
    // Pass values as context to avoid errors with some custom validations that destructure values
    validateYupSchema(clonedValues, schema, true, clonedValues);
  } catch (e) {
    if (e instanceof ValidationError) {
      e.inner.forEach((error) => {
        deleteProp(clonedValues, toPath(error.path));
      });
    }
  }

  return clonedValues;
};

export const removeNonNumbers = (str = '') => str.replace(/[^\d]/g, '');

export const isNumber = (n: number | undefined | null): boolean => typeof n === 'number' && n >= 0;

export const formatNumber = (val = '') => val.replace(/-|\)|\(| |\$|,/g, '');

export const getAge = (birthDate: Date) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const truncateString = (str: string, n: number) =>
  str.length > n ? `${str.slice(0, n)}...` : str;

export const monthsList = () => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
};

export const getTimeString = (message: Message) => {
  const dateCreated = message.dateCreated
    ? message.dateCreated instanceof Date
      ? message.dateCreated
      : new Date(message.dateCreated)
    : null;
  const minutesAgo = getMinutesAgo(dateCreated);

  if (minutesAgo !== null && minutesAgo < 60) {
    return minutesAgo <= 1 ? 'Just now' : `${minutesAgo} minutes ago`;
  }
  return dateCreated ? formatDateWithTime(dateCreated.toISOString()) : '';
};

export const getDateWithLastDayOfTheMonth = (date: Date | null): Date | null => {
  return date ? new Date(date.getFullYear(), date.getMonth() + 1, 0) : null;
};
