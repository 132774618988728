import { VStack } from '@chakra-ui/react';

import ContractHandler from './ContractHandler';
import DashboardUploadHandler from './DashboardUploadHandler';
import DownPaymentHandler from './DownPaymentHandler';
import DueFollowUpHandler from './DueFollowUpHandler';
import DuplicatedAffiliateHandler from './DuplicatedAffiliateHandler';
import DuplicatedDealImportHandler from './DuplicatedDealImportHandler';
import DuplicatedInfoHandler from './DuplicatedInfoHandler';
import InactivityImportHandler from './InactivityImportHandler';
import PayoffRequestHandler from './PayoffRequestHandler';
import TextHandler from './TextHandler';

const NotificationHandler = () => {
  return (
    <VStack
      position="fixed"
      zIndex="5501"
      right={0}
      w="485px"
      overflowY="auto"
      py={1}
      pb={2}
      maxH="90%"
    >
      <TextHandler />
      <PayoffRequestHandler />
      <ContractHandler />
      <DashboardUploadHandler />
      <DownPaymentHandler />
      <InactivityImportHandler />
      <DueFollowUpHandler />
      <DuplicatedInfoHandler />
      <DuplicatedDealImportHandler />
      <DuplicatedAffiliateHandler />
    </VStack>
  );
};

export default NotificationHandler;
