import { useContext } from 'react';

import { MdRestoreFromTrash } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DealStateEnum } from '../../../gql/dealGql';
import { useReviveBootMutation } from '../../../gql/generated/graphql';

import DynamicSizedButton from '../../shared/DynamicSizedButton';

import ROUTES from '../../../constants/routes';
import { DealContext } from '../../../libs/DealContext';
import { logger } from '../../../libs/Logger';

const ReviveBootAction = () => {
  const { deal } = useContext(DealContext);
  const [reviveBoot] = useReviveBootMutation();
  const history = useHistory();

  const handleClick = () => {
    if (!deal.id) {
      return;
    }

    reviveBoot({
      variables: {
        id: deal.id,
      },
    })
      .then(() => {
        toast.success('Success');
        history.push(ROUTES.DASHBOARD);
      })
      .catch((e) => {
        logger.error('ReviveBootAction.tsx', '', null, e);
        toast.error('Could not revert deal state');
      });
  };

  return (
    <DynamicSizedButton
      ariaLabel="revive-boot"
      size={{ sm: 'lgWithIconLeft' }}
      icon={<MdRestoreFromTrash size="20px" />}
      variant="boot"
      isRound
      hidden={deal.state !== DealStateEnum.Booted}
      onClick={handleClick}
      label="Revive Boot"
    />
  );
};

export default ReviveBootAction;
