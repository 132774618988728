import { Button, Text } from '@chakra-ui/react';
import { MdPhone } from 'react-icons/md';

type PhoneNumberButtonProps = {
  phoneNumber: string | undefined;
  handleClick: (phoneNumber: string) => void;
  sectionLabel?: string;
  buttonLabel?: string;
};

const PhoneNumberButton = ({
  phoneNumber,
  handleClick,
  sectionLabel,
  buttonLabel = 'CELL PHONE',
}: PhoneNumberButtonProps) => {
  if (!phoneNumber) {
    return null;
  }

  return (
    <>
      {sectionLabel && (
        <Text color="black" pl={2} pt={1}>
          {sectionLabel}
        </Text>
      )}
      <Button
        leftIcon={<MdPhone />}
        size="lgWithIconLeft"
        variant="secondary"
        onClick={() => handleClick(phoneNumber)}
      >
        {`${buttonLabel}: ${phoneNumber}`}
      </Button>
    </>
  );
};

export default PhoneNumberButton;
