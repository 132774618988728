import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';

import {
  DuplicatedDealImport,
  useOnDuplicatedDealImportSubscription,
} from '../../gql/generated/graphql';

import NotificationToast from './components/NotificationToast';

const DuplicatedDealImportHandler = () => {
  const [deals, setDeals] = useState<DuplicatedDealImport[]>([]);

  useOnDuplicatedDealImportSubscription({
    onData: ({ data }) => {
      if (!data.data?.onDuplicatedDealImport) {
        return;
      }

      const newDeal = data.data.onDuplicatedDealImport;

      if (!deals.some((deal) => deal.deal_id === newDeal.deal_id)) {
        setDeals([...deals, newDeal]);
      }
    },
  });

  return (
    <>
      {deals.map((d) => (
        <NotificationToast
          key={`duplicated-deal-import-${d.deal_id}`}
          dealId={d.deal_id ?? null}
          slideFadeIn={!!d.first_name}
          title={`${d.first_name} ${d.last_name} - Deal ${d.deal_id}`}
          titleIcon={FaRegCopy}
          titleIconColor="green"
          subTitle="Duplicate Deal Detected"
          details={
            <Text>
              A duplicate credit app was submitted on Web with a VIN matching this deal. Click into
              your deal to see more details.
            </Text>
          }
          onClose={() => setDeals(deals.filter((p) => p.deal_id !== d.deal_id))}
        />
      ))}
    </>
  );
};

export default DuplicatedDealImportHandler;
