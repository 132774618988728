import { useContext } from 'react';

import { PermissionEnum } from '../constants/permissions';
import { AbilityContext } from '../libs/contextLib';

export const useHasPermission = (permission: PermissionEnum | PermissionEnum[]) => {
  const abilities = useContext(AbilityContext);

  if (typeof permission === 'string') {
    return abilities.has(permission);
  }

  if (Array.isArray(permission) && permission.length) {
    return permission.some((p) => abilities.has(p));
  }

  return false;
};
