import { ComponentProps, ReactNode } from 'react';

import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react';

import CopyButtonV2 from '../CopyButton/CopyButtonV2';
import Card from '../shared/Card';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';

export type InfoCardValue = string | undefined | { render: ReactNode; value: string | undefined };

interface InfoCardProps extends ComponentProps<typeof Card> {
  name: string;
  values?: Record<string, InfoCardValue>;
  hideEmpty?: boolean;
  showEditable?: boolean;
  editAction?: () => void;
  children?: ReactNode;
}

interface InfoRowProps {
  name: string;
  value: InfoCardValue;
  hideEmpty?: boolean;
}

const formattedNumberFields = [
  'Phone',
  'Payoff Amount',
  'KBB Lending',
  'KBB Retail',
  'JD Power Lending',
  'JD Power Retail',
  'Mileage',
  'MMR',
  'Old Lease Payment',
  'Old Payment',
  'CB Phone',
];

export const LICENSE_PLATE_NUMBER = 'License Plate Number';
export const VIN = 'VIN';

export const InfoRow = ({ name, value, hideEmpty = false }: InfoRowProps) => {
  const copyValue = typeof value === 'object' && !!value ? value.value : value;

  if (hideEmpty && !copyValue) {
    return null;
  }

  return (
    <Tr fontSize="14px" width="100%">
      <Td border="none" fontWeight="medium" padding="8px 12px" verticalAlign="middle">
        {name}
      </Td>
      <Td
        border="none"
        fontWeight="normal"
        padding="8px 12px"
        verticalAlign="middle"
        wordBreak="break-word"
      >
        {typeof value === 'object' && !!value ? value.render : value}
      </Td>
      <Td border="none" padding="8px 12px" textAlign="right" verticalAlign="middle">
        {name !== 'SSN' && (
          <CopyButtonV2
            h="100%"
            value={copyValue as string}
            isFormatted={formattedNumberFields.includes(name)}
            forceUppercase={name === LICENSE_PLATE_NUMBER || name === VIN}
          />
        )}
      </Td>
    </Tr>
  );
};

const InfoCardV2 = ({
  name,
  values,
  hideEmpty = false,
  showEditable,
  editAction,
  children,
  ...rest
}: InfoCardProps) => {
  return (
    <Card variant="roundedNoMargin" {...rest}>
      <CardHeaderV2
        title={name}
        onClick={showEditable && editAction ? () => editAction?.() : undefined}
      />
      {values && Object.keys(values).length && (
        <Table mt={3}>
          <Tbody>
            {Object.entries(values).map(([key, value]) => (
              <InfoRow key={key} name={key} value={value} hideEmpty={hideEmpty} />
            ))}
          </Tbody>
        </Table>
      )}
      <Box p={2}>{children}</Box>
    </Card>
  );
};

export default InfoCardV2;
