import { Button } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';

import { Deal } from '../../gql/dealGql';
import { useReviveBootMutation } from '../../gql/generated/graphql';

import { useUser } from '../../hooks/useUser';

interface ReviveBootButtonProps {
  closeDuplicateModal?: () => void;
  duplicateDeals: Deal[];
}

const ReviveBootButton = ({ duplicateDeals, closeDuplicateModal }: ReviveBootButtonProps) => {
  const user = useUser();
  const [reviveBoot] = useReviveBootMutation();

  const handleClick = async () => {
    const bootedDeal = duplicateDeals[0];
    if (!bootedDeal.id) {
      return;
    }

    try {
      await reviveBoot({
        variables: {
          id: bootedDeal.id,
          setterId: user.id,
        },
      });

      // Open the link in a new tab after the mutation completes
      window.open(`/deals/${bootedDeal.id}`, '_blank', 'noopener,noreferrer');

      if (closeDuplicateModal) {
        closeDuplicateModal();
      }
    } catch (error) {
      console.error('Error reviving boot:', error);
    }
  };

  return (
    <Button variant="boot" size="lgWithIconLeft" leftIcon={<BiTrash />} onClick={handleClick}>
      Revive Boot
    </Button>
  );
};

export default ReviveBootButton;
