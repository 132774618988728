import { Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';
import { MdOutlinePhoneEnabled } from 'react-icons/md';

import { formatPhoneNumberForDisplay } from '../../libs/utils';

interface Props {
  onPanelClick: (v: boolean) => void;
  name?: string;
  phoneNumber?: string;
}

const SmallCurrentCallPanel = ({ onPanelClick, name, phoneNumber }: Props) => {
  return (
    <Flex
      w="20%"
      position="fixed"
      left="0.5%"
      bottom="0.5%"
      bg="midnightblue"
      alignItems="center"
      rounded="2xl"
      zIndex={2000}
      p="15px"
      justifyContent="space-between"
      onClick={() => onPanelClick(false)}
    >
      <HStack>
        <Flex
          rounded="full"
          w="30px"
          h="30px"
          bg="green"
          ml={2}
          justifyContent="center"
          alignItems="center"
          px={4}
        >
          <Icon as={MdOutlinePhoneEnabled} color="white" size={20} bg="transparent" m={0} />
        </Flex>

        <VStack spacing={0} ml={4} color="white" fontSize="md" fontWeight="bold">
          <Text> {name || ''}</Text>
          <Text>{formatPhoneNumberForDisplay(phoneNumber || '')}</Text>
        </VStack>
      </HStack>

      <IconButton
        variant="icon"
        icon={<BiExpand />}
        color="white"
        fontSize="3xl"
        aria-label="Expand Panel"
      />
    </Flex>
  );
};

export default SmallCurrentCallPanel;
