import { Button } from '@chakra-ui/react';
import { BiCopy } from 'react-icons/bi';

import { Deal } from '../../gql/dealGql';

interface ReviveBootButtonProps {
  closeDuplicateModal?: () => void;
  duplicateDeals: Deal[];
}

const CopyButton = ({ duplicateDeals, closeDuplicateModal }: ReviveBootButtonProps) => {
  return (
    <Button
      leftIcon={<BiCopy />}
      onClick={() => {
        const links = duplicateDeals
          .map((d) => `${window.location.origin}/deals/${d.id}`)
          .join(',');
        navigator.clipboard.writeText(links);
        if (closeDuplicateModal) {
          closeDuplicateModal();
        }
      }}
    >
      Copy Link
    </Button>
  );
};

export default CopyButton;
